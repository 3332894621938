<template>
  <div class="widgetContainer">
    <div class="loginHeader">
      <img class="loginHeader__logo" :src="logoUrl" alt="logo">
      <div class="loginHeader__footer">
        <p class="cancel" @click="cancel">
          {{ $t('cancel') }}
        </p>
        <p class="description">
          {{
            getReplaceCardData
              ? $t('card.text.replaceCard')
              : $t(
                getSelectedCard
                  ? getSelectedCard.cardType === 'physical'
                    ? 'physicalCard_edit_NavTitle'
                    : 'virtualCard_edit_NavTitle'
                  : 'card_create'
              )
          }}
        </p>
        <p class="submit" :class="{ disabled: !isFormValid }" @click="next()">
          {{ $t('next') }}
        </p>
      </div>
    </div>
    <div class="widgetContainer__body">
      <el-form
        class="wise-form"
        ref="cardDetail"
        :model="formData"
        :rules="formRules">
        <el-form-item
          class="is-no-asterisk"
          prop="label"
          :label="$t('card_label')">
          <el-input v-model="formData.label" />
        </el-form-item>
      </el-form>
    </div>
  </div>
</template>
<script>
import { mapMutations, mapGetters, mapActions } from 'vuex';
import CardMixin from '../mixins/card';
export default {
  name: 'CardLabelForm',
  mixins: [CardMixin],
  data() {
    return {
      formData: {
        label: ''
      },
      formRules: {}
    };
  },
  created() {
    /** @type {import('@m/card/store/state').cardState['selectedCard']} */
    const card = this.getSelectedCard;
    if (this.getReplaceCardData && this.getReplaceCardData.label) {
      this.formData.label = this.getReplaceCardData.label;
    } else if (card && card.label) {
      this.formData.label = card.label;
    }
  },
  computed: {
    ...mapGetters('user', ['getPersonDetails']),
    ...mapGetters('card', [
      'getCreateCardData',
      'getSelectedCard',
      'getReplaceCardData'
    ]),
    isFormValid() {
      return this.formData.label !== '';
    }
  },
  methods: {
    ...mapMutations('card', ['updateCreateCardData', 'updateReplaceCardDate']),
    ...mapActions('card', ['updateCard']),
    cancel() {
      if (this.getSelectedCard) {
        this.drawerPush('CardManagement');
      } else if (this.getReplaceCardData) {
        this.drawerPush('CardManagement');
      } else {
        this.drawerBack();
      }
    },
    next() {
      /** @type {import('@m/card/store/state').cardState['selectedCard']} */
      const selectedCard = this.getSelectedCard;
      if (selectedCard && this.getReplaceCardData) {
        //replace flow

        this.updateReplaceCardDate({
          ...this.getReplaceCardData,
          label: this.formData.label
        });
        setTimeout(() => {
          this.$router.push('/card/replaceCardDetails');
        }, 0);

        return;
      }
      if (selectedCard) {
        const loader = this.showLoader();
        this.updateCard({
          cardId: selectedCard.id,
          payload: {
            label: this.formData.label
          }
        })
          .then(() => {
            this.drawerPush('CardDetails');
          })
          .catch((e) => {
            console.log(e);
            this.apiErrorMessage(e);
          })
          .finally(() => {
            loader.close();
          });
        return;
      }
      const card = this.getCreateCardData;
      this.updateCreateCardData({
        ...card,
        label: this.formData.label
      });

      setTimeout(() => {
        this.drawerPush('CardSpendingForm');
      }, 0);
    }
  }
};
</script>
